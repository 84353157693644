
import ZhCN from '@C/locales/zh-CN';
import _isString from 'lodash/isString';
import ui from './zhCN/ui';
import vm from './zhCN/vm';
import page from './zhCN/page';
import enums from './zhCN/enums';
import code from './zhCN/code';
import materiel from './zhCN/materiel';
import material from './zhCN/material';
import orderForm from './zhCN/orderForm';

function formatMessage(messages: any, msgs: any, prefix: string) {
  Object.keys(msgs).forEach(key => {
    const val = msgs[key];
    if (_isString(val)) {
      messages[`${prefix}.${key}`] = val;
    } else {
      formatMessage(messages, val, `${prefix}.${key}`);
    }
  });
}

function buildMessage(msgs: any, module: string) {
  const messages: any = {};
  formatMessage(messages, msgs, module);
  return messages;
}

export default {
  ...ZhCN,
  ...buildMessage(ui, 'ui'),
  ...buildMessage(vm, 'vm'),
  ...buildMessage(page, 'page'),
  ...buildMessage(enums, 'enums'),
  ...buildMessage(code, 'api.code'),
  ...buildMessage(materiel, 'materiel'),
  ...buildMessage(material, 'material'),
  ...buildMessage(orderForm, 'orderForm'),
};
