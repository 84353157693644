
import AccessFactory, { AccessContext } from '@C/utils/access';
import React, { useMemo } from 'react';

// @ts-ignore
import { useSelector } from 'umi';

const AppRootWrapper: React.FC<{
  appCode: string;
  configs?: {
    funcs?: string[];
  };
}> = (props) => {
  const user = useSelector((state: any) => state.user);

  const accessFn = useMemo(() => {
    if (user) {
      return AccessFactory(user);
    }

    return () => true;
  }, [ user.appResources ]);

  return (
    <AccessContext.Provider value={{
      check: accessFn
    }}>
      { props.children }
    </AccessContext.Provider>
  );
}

export default AppRootWrapper;
