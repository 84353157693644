
import { IAppRoute } from '@/routers';
import { useSelector } from 'umi';
import { IModel, ModelAction, modelDispatch, ModelReducer, ModelState, ModelStateSelector } from './model';

export interface AppModelState extends ModelState {
  init: boolean,
  menus: any[],
  menuCollapsed: boolean;
  matchMenus: any[],
  matchMenuKeys: any[],
  matchRoutes: IAppRoute[]
}

export interface IAppModel extends IModel<AppModelState> {
  effects: {
  };

  reducers: {
    save: ModelReducer<AppModelState>;
    setMenuCollapsed: ModelReducer<AppModelState>;
  };
}

type EffectKeys = keyof IAppModel["effects"];

type ActionKeys = EffectKeys | keyof IAppModel["reducers"]

export type AppModelAction = ModelAction<ActionKeys, any>;

const selector: ModelStateSelector<AppModelState> = (state: any) => state.app;

export function useAppState(): AppModelState {
  return useSelector(selector);
}

export const appDispatch = (action: AppModelAction): Promise<any> => {
  return modelDispatch(Object.assign({ type: `app/${action.type}` }, action));
}

const UserModel: IAppModel = {
  state: {
    init: true,
    menus: [],
    matchMenus: [],
    matchMenuKeys: [],
    menuCollapsed: false,
    matchRoutes: []
  },

  effects: {
  },

  reducers: {
    setMenuCollapsed(state, { payload }) {
      return {
        ...state,
        menuCollapsed: payload === true
      };
    },
    save(state, { payload }) {
      return {
        ...state,
        ...payload
      };
    }
  }
};

export default UserModel;
