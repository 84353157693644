// @ts-nocheck

import Styles from '@C/styles/config';
import {
  ConfigKey as CommonKeys,
  getConfig,
  setConfig
} from '@C/utils/config';

export const AppCode = 'ERP';

export const DefVersion = Def_Version || 'ERP';

export const IsDev = Is_Dev || false;

export const ConfigKey = {
  ...CommonKeys,
  storageDataInfo: "_BASE_INFO_STORAGE_TRANSFER_ERP"
};

export {
  Styles,
  getConfig,
  setConfig
};
