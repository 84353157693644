
import React from 'react';
import AppRootWrapper from '@C/components/AppRootWrapper';
import { AppCode, IsDev } from '@/utils/config';

// import * as Sentry from "@sentry/react";
// import { BrowserTracing } from "@sentry/tracing";
// import { DefVersion } from './utils/config';
//
// if (!IsDev) {
//   Sentry.init({
//     dsn: "http://6b0197733dcc4c26af22f7953084bf76@47.96.228.87:9192/3",
//     release: DefVersion,
//     integrations: [new BrowserTracing()],

//     // We recommend adjusting this value in production, or using tracesSampler
//     // for finer control
//     tracesSampleRate: 1.0,
//   });
// }

export const qiankun = {
  async bootstrap(props: any) {
    console.log('erp bootstrap', props);
  },

  async mount(props: any) {
    console.log('erp mount', props);
  },

  async unmount(props: any) {
    console.log('erp unmount', props);
  }
};

export function rootContainer (children: any) {
  return (
    <AppRootWrapper appCode={AppCode}>
      { children }
    </AppRootWrapper>
  );
}
