export default {
  inventory: {
    order: '搜索业务单号'
  },
  bpmList: {
    show: '查看'
  },
  applyList: {
    tab: {
      list: '库存申请'
    },

    button: {
      edit: '编辑',
      cancel: '作废',
      withdraw: '撤回',
      delete: '删除',
      commit: '审核',
      print: '打印',
      export: '导出',
      finish: '完成'
    },

    card: {
      title: {
        'process': '申请进度',
        'materialInformation': '物料信息',
        'remark': '备注信息'
      }
    },

    step: {
      title: {
        'create': '提交申请',
        'finish': '完成申请'
      }
    },

    info: {
      'createUserName': '申请人',
      'createdAt': '创建时间',
      'typeName': '申请类型',
      'wareHouse': '仓库',
      'relationCode': '关联单据',
      'relationType': '单据类别',
      'state': '状态',
      'remark': '备注',
      'applyNum': '申请单号',
      'type': '类型',
      'outWareHouse': '出库仓库',
      'inWareHouse': '入库仓库',
      'inNum': '已入库数量',
      'outNum': '已出库数量',
      'moveNum': '已移库数量',
      'orgName': '部门',
      'applyAt': '申请日期'
    },

    placeholder: {
      type: '选择类型',
      typeSupplier: '选择供应商',
      out: '出仓',
      in: '入仓',
      order: '订单号'
    }

  },

  sale: {
    add: {
      card: {
        productInfo: '产品信息',
        remarkInfo: '备注信息',
      }
    },
    button: {
      addMake: '生成生产单',
    }
  },

  material: {
    plan: {
      button: {
        mrp: 'MRP运算',
        procurement: '转采购申请',
        orderForm: '生成采购订单',
      },
      message: {
        notSupplier: '不可以选择不同的供应商！'
      }
    }
  },

  bizTask: {
    table: {
      row: {
        relocationConfirm: '确认移库？',
      }
    },
    button: {
      in: '入库',
      batchIn: '批量入库',
      out: '出库',
      batchOut: '批量出库',
      addWarehouseNum: '添加仓库数量',
      fulfilWarehouseNum: '完成入库数据',
      move: '移库'
    },
    tabs: {
      addIn: '新建入库',
      addIOut: '新建出库',
      move: '移库',
      addInMM: '采购入库',
      addOutMM: '采购退货',
      inIe: '退料管理',
      outIe: '补料管理',
      outIECC: '生产领料',
    },
    message: {
      warehouse: '请选择仓库！'
    }
  },
}
