
export default {
  loading: '加载中...',
  title: '比升智能管理平台',
  button: {
    add: '新增',
    edit: '编辑',
    update: '修改',
    delete: '删除',
    save: '保存',
    search: '查询',
    log: '日志',
    output: '导出',
    import: '导入',
    return: '返回',
    withdraw: '撤回',
    commit: '审核',
    print: '打印',
    finish: '完成',
    copy: '复制',
    more: '更多',
  },
  modal: {
    title: {
      edit: '编辑',
      confirm: '确认',
      error: '错误'
    },
    confirmDelete: {
      title: '提示',
      content: '确定需要删除吗？',
      contentCancel: '确定需要作废吗？',
      cancelAddApply: '是否作废此单据？',
      isNotApplyCommit: '是否完成库存申请单？',
      notCancelAddApply: '申请单库存任务已开始，不允许作废申请单',
      isNotCommit: '是否确定审核！',
      commitAddApply: '已开始任务，是否完成申请单？',
      okText: '确定',
      cancelText: '取消',
    }
  },
  table: {
    row: {
      action: '操作',
      edit: '编辑',
      delete: '删除',
      deleteConfirm: '确认删除数据？',
      add: '添加',
      added: '已添加',
      remove: '移除',
      position: '库位',
      finish: '完成',
      print: '打印'
    }
  },
  select: {
    placeholder: {
      warehouse: '请选择仓库'
    }
  },
  radio: {
    button: {
      order: '按单号',
      product: '按明细'
    }
  },
  input: {
    placeholder: {
      order: '搜索业务单号'
    }
  }
};
